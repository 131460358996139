/* eslint-disable prefer-destructuring */
import React from 'react'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

const BreadcrumbWrapper = styled.div`
  height: 50px;

  .breadcrumb {
    background-color: transparent;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0;

    &__list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
    }

    @media (max-width: 500px) {
      padding-left: 0;
      padding-right: 3px;

      .breadcrumb__link {
        font-size: ${(props) => props.theme.font.size.s} !important;
      }

      .breadcrumb__separator {
        padding-left: 3px !important;
      }
    }

    .breadcrumb__link {
      color: ${(props) => props.theme.color.text.main} !important;
      text-transform: uppercase;
      font-size: ${(props) => props.theme.font.size.sm}px !important;

      &[aria-current] {
        color: ${(props) => props.theme.color.text.striking} !important;
      }
    }

    .breadcrumb__separator {
      padding: 0 15px 0 15px;
    }
  }
`

const CustomBreadCrumb = ({ data, className, pageContext }) => {
  let title = ''

  if (typeof data.title === 'object') {
    title = data.title[0]
  } else if (typeof data.title === 'string') {
    title = data.title
  } else {
    title = 'Onbekend'
  }

  return (
    <BreadcrumbWrapper className={`d-flex align-items-center color-background-secondary ${className ? `${className}` : ``}`}>
      <div className="container">
        <Breadcrumb crumbSeparator=" > " crumbs={pageContext.breadcrumb.crumbs} crumbLabel={parse(title)} />
      </div>
    </BreadcrumbWrapper>
  )
}

export default CustomBreadCrumb
