/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Images
import mailIcon from 'img/snel_red_email.svg'

// Components
import { SecondHeader, Paragraph } from 'components/StyledComponents'
import CustomLink from 'components/CustomLink'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'
import Plaatjie from '@ubo/plaatjie'

const TeamWrapper = styled.div``

const Team = ({ className }) => {
  const {
    medewerkers: {
      edges: medewerkers
    }
  } = useStaticQuery(graphql`{
    medewerkers: allWpMedewerker(sort: {fields: date}) {
      edges {
        node {
          recapMedewerker {
            name
            function
            email
            story
            image {
              localFile {
                childImageSharp {
                 gatsbyImageData(quality: 100, width: 1000)
                }
              }
            }
          }
        }
      }
    }
  }`)

  return (
    <TeamWrapper className={`${className ? `${className}` : ``}`}>
      <div className="container">
        <SecondHeader className="text-uppercase pb-5 font-size-24 font-weight-xl">Maak kennis met ons team</SecondHeader>
        <div className="row">
          {medewerkers.map((member, index) => (
            <TeamMember key={index} data={member.node} className="col-6 col-md-4" />
          ))}

        </div>
      </div>
    </TeamWrapper>
  )
}

export default Team

const Overlay = styled.div`
  transition: 0.5s;
  opacity: 0.9;
  max-height: 100%;
  min-height: 225px;
  width: 100%;
  bottom: -200%;
`

const ImageContainer = styled.div`
  overflow: hidden;
  cursor: pointer;
  :hover {
    ${Overlay} {
      bottom: 0;
    }
  }

  :focus {
    ${Overlay} {
      bottom: 0;
    }
  }
`

const Image = styled(Plaatjie)`
  border-radius: 20px;
  height: 450px;
  object-fit: cover;
`


const TeamMember = ({ data, className }) => {
  return (
    <div className={`pb-5 position-relative ${className ? `${className}` : ``}`}>
      <ImageContainer className="position-relative">
        <Image loading="lazy" image={data.recapMedewerker.image} alt="" />
        <Overlay className="color-background-main font-size-s p-3 pb-4 text-justify color-text-light position-absolute">
          {parse(data.recapMedewerker.story)}
        </Overlay>
      </ImageContainer>
      <div className="p-2 row position-relative">
        <div className="col-9">
          <SecondHeader className="font-size-m font-weight-xl text-uppercase">{data.recapMedewerker.name}</SecondHeader>
          <Paragraph className="font-size-sm">{data.recapMedewerker.function}</Paragraph>
        </div>
        <div className="col-3 d-flex justify-content-center align-items-center">
          <CustomLink external to={`mailto:${data.recapMedewerker.email}`}>
            <img src={mailIcon} alt="" />
          </CustomLink>
        </div>
      </div>
    </div>
  )
}