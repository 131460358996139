/* eslint-disable arrow-body-style */
import React from 'react'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

const QuoteBannerWrapper = styled.div`
  @media (max-width: 991px) {
    margin-top: 220px !important;
    z-index: 2;
  }

  @media (max-width: 463px) {
    margin-top: 160px !important;
  }

  @media (max-width: 353px) {
    margin-top: 130px !important;
  }
`

const QuoteContainer = styled.div`
  img {
    height: 16px;
    width: 20px;
  }

  p {
    font-size: ${(props) => props.theme.font.size.sm}px;
    line-height: 1.61;
    letter-spacing: -0.24px;
    width: 70%;
    margin-bottom: 0;
  }

  @media (max-width: 991px) {
    p {
      width: 100%;
    }
  }
`

const ImageContainer = styled.div`
  img {
    top: -87px;
    height: 310px;
  }

  @media (max-width: 1199px) {
    img {
      top: -58px;
    }
  }

  @media (max-width: 991px) {
    img {
      top: -380px;
      z-index: -1;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (max-width: 767px) {
    img {
      top: -410px;
    }
  }

  @media (max-width: 463px) {
    img {
      height: 250px;
      top: -390px;
    }
  }

  @media (max-width: 353px) {
    img {
      height: 220px;
      top: -390px;
    }
  }
`

const QuoteBanner = ({ data, className }) => {
  return (
    <QuoteBannerWrapper className={`${className ? `${className}` : ``}`}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <QuoteContainer>
              <img src={data.pageAbout.quote.icon.localFile.publicURL} alt="" />
              {parse(data.pageAbout.quote.text)}
            </QuoteContainer>
          </div>
          <div className="col-12 col-lg-6">
            <ImageContainer className="position-relative">
              <img className="position-absolute" src={data.pageAbout.quote.image.localFile.publicURL} alt="" />
            </ImageContainer>
          </div>
        </div>
      </div>
    </QuoteBannerWrapper>
  )
}

export default QuoteBanner