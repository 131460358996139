/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import redChevronRight from 'img/snel_red_chevron_right.svg'

// Components
import { Paragraph } from 'components/StyledComponents'
import CustomLink from 'components/CustomLink'


const StyledCustomLink = styled(CustomLink)`
  ${Paragraph} {
    transition: 0.25s;
    width: 264px;
    height: 50px;
    text-transform: inherit !important;
    font-size: 18px;
    border-radius: 20px;
    
    :hover {
      background: #812a37;
    }
  }
`

const LinkCustomLink = styled(CustomLink)`
  &[aria-current] {
    ${Paragraph} {
      color: ${(props) => props.theme.color.text.striking};
    }
  }
`

const DienstenLijstWrapper = styled.div``

const DienstenContainer = styled.div`
  width: 264px;
`

const Links = styled.div``

const DienstenLijst = ({ className }) => {
  const {
    diensten: {
      edges: diensten
    }
  } = useStaticQuery(graphql`{
    diensten: allWpPage(filter: {parentDatabaseId: {eq: 291}}) {
      edges {
        node {
          title
          uri
          databaseId
        }
      }
    }
  }`)

  return (
    <DienstenLijstWrapper className={`${className ? `${className}` : ``}`}>
      <DienstenContainer className="d-flex justify-content-end flex-wrap">
        <StyledCustomLink to="/diensten">
          <Paragraph className="float-left py-2 px-4 d-flex justify-content-center align-items-center color-background-main color-text-light text-uppercase font-weight-xl font-size-m">Diensten</Paragraph>
        </StyledCustomLink>
        <Links className="d-flex justify-content-end flex-wrap pt-3">
          {diensten.map((link, index) => (
            <LinkCustomLink to={link.node.uri} className="d-flex w-100 pl-4 py-2" key={index}>
              <img className="pr-2" src={redChevronRight} alt="" />
              <Paragraph className="text-uppercase font-weight-xl">{link.node.title}</Paragraph>
            </LinkCustomLink>
          ))}
        </Links>
      </DienstenContainer>
    </DienstenLijstWrapper>
  )
}

export default DienstenLijst